import { useEffect } from "react";
import useRefreshToken, { setAuth } from "./useRefreshToken";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { Navigate } from "react-router-dom";
// import PageRoutes from "../utils/constants";

export type authDetailsType = {
  AccessToken: string;
  RefreshToken: string;
  UserID?: string | number;

  FirstName?: string;
  LastName?: string;

  Email?: string;
};

export const getCurrentAuth = () => {
  const localAuth = localStorage.getItem("auth");
  const auth: authDetailsType = localAuth
    ? JSON.parse(localStorage.getItem("auth") || "")
    : {};
  return auth;
};

export const Logout = () => {
  localStorage.removeItem("auth");
};

const useAxiosPrivate = (axiosInstance: AxiosInstance) => {
  const refresh = useRefreshToken();
  // var auth = useTypedSelector(getAuthState, shallowEqual);
  // const dispatch = useDispatch();
  const auth = getCurrentAuth();

  const requestIntercept = axiosInstance.interceptors.request.use(
    // @ts-ignore: Unreachable code error
    (config: AxiosRequestConfig) => {
      if (!config.headers!["Authentication"]) {
        config.headers!["Authentication"] = `Bearer ${auth?.AccessToken}`;
      }
      console.log(config);
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  const responseIntercept = axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      const statusCode = error.response ? error.response.status : null;
      if (statusCode === 401 && !prevRequest._retry) {
        prevRequest._retry = true;

        const newaccess: any = await refresh();
        if (!newaccess) {
          // dispatch(logout());
          Logout();
        } else {
          axiosInstance.defaults.headers.common[
            "Authentication"
          ] = `Bearer ${newaccess}`;
          prevRequest.headers["Authentication"] = `Bearer ${newaccess}`;
          return axiosInstance(prevRequest);
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxiosPrivate;
