import React, { useEffect, useState } from "react";
import {
  Box,
  Backdrop,
  CircularProgress,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Card,
  Grid,
} from "@mui/material";
import PlayerCard from "../components/LineUp/PlayerCard";
import FooterButtons from "../components/Common/FooterButtons";
import { useParams, useLocation } from "react-router-dom";
import { getQueryParams } from "../utils/commonFunctions";
import GameDataContext from "../providers/GameDataProvider";
import AuthContext from "../providers/AuthProvider";
import axios from "../utils/axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const GoalEntry = () => {
  const params: any = useParams();
  const location = useLocation();
  const [toBeAdded, setToBeAdded] = useState<any>({
    goal: [],
    ass1: [],
    ass2: [],
    plus: [],
    minus: [],
  });
  const [maxLimits, setMaxLimits] = useState({ plus: 5, minus: 5 });
  const [headerFlow, setHeaderFlow] = useState<number>(0);

  const authCtx = React.useContext(AuthContext);
  const client = useAxiosPrivate(axios);

  const gameDataCtx = React.useContext(GameDataContext);
  const { gameData, isSkeleton, setIsSkeleton, getGameInfo } = gameDataCtx;
  const { goal, event, ass1, ass2 } = getQueryParams(location.search);

  const handleAddedPlayers = (index: number, type?: string) => {
    const mode =
      headerFlow === 0
        ? "goal"
        : headerFlow === 1
        ? "ass1"
        : headerFlow === 2
        ? "ass2"
        : type === "plus"
        ? "plus"
        : "minus";
    setToBeAdded((prevState: any) => ({
      ...prevState,
      [mode]: prevState[mode].includes(index)
        ? prevState[mode].filter((item: number) => item !== index)
        : [...prevState[mode], index],
    }));
  };

  const arePlayersValid = (type?: string) => {
    const { plus, minus } = toBeAdded;
    if (!type) {
      return plus.length === maxLimits.plus && minus.length === maxLimits.minus;
    } else if (type === "plus") {
      return plus.length === maxLimits.plus;
    } else if (type === "minus") {
      return minus.length === maxLimits.minus;
    }
  };

  const handleModGoal = async () => {
    try {
      setIsSkeleton(true);

      const goalEntry = await client.post("/", {
        action: "mod_goal",
        game_id: params.id,
        goal,
        ass1,
        ass2,
        event_id: event,
        pluses: toBeAdded.plus,
        minuses: toBeAdded.minus,
      });

      if (goalEntry.data?.error) {
        authCtx.setError({
          message: goalEntry.data.error?.message,
          isError: true,
        });
        setIsSkeleton(false);
      } else {
        setIsSkeleton(false);
        window.history.back();
      }
    } catch (error: any) {
      authCtx.setError({ isError: true, message: error.message });
      setIsSkeleton(false);
    }
  };

  const filteredEvents = (data: any) => {
    const updatedData = [];
    for (const period in data) {
      updatedData.push(...data[period]);
    }
    return updatedData.filter(
      (item) =>
        item.type === "goal" &&
        item.goal_player_id === goal &&
        item.event_id === event &&
        item.ass1_player_id === ass1 &&
        item.ass2_player_id === ass2
    );
  };

  const getPlusMinusPlayers = (data: any) => {
    return filteredEvents(data).map((finalItem: any) => ({
      plus: finalItem.plus.map((item: any) => item.plus_player_id),
      minus: finalItem.minus.map((item: any) => item.minus_player_id),
    }));
  };

  const handlePlayersLimits = (data: any) => {
    const goalType = filteredEvents(data)[0]?.goal_type;

    switch (goalType) {
      case 0:
        return setMaxLimits({ plus: 5, minus: 5 });
      case 1:
        return setMaxLimits({ plus: 0, minus: 0 });
      case 2:
        return setMaxLimits({ plus: 0, minus: 0 });
      case 3:
        return setMaxLimits({ plus: 0, minus: 0 });
      case 4:
        return setMaxLimits({ plus: 0, minus: 0 });
      case 5:
        return setMaxLimits({ plus: 4, minus: 5 });
      case 6:
        return setMaxLimits({ plus: 3, minus: 4 });
      case 7:
        return setMaxLimits({ plus: 3, minus: 5 });
      case 8:
        return setMaxLimits({ plus: 4, minus: 4 });
      case 9:
        return setMaxLimits({ plus: 3, minus: 3 });
      case 10:
        return setMaxLimits({ plus: 0, minus: 0 });
      case 11:
        return setMaxLimits({ plus: 0, minus: 0 });
      default:
        return setMaxLimits({ plus: 0, minus: 0 });
    }
  };

  const plusPlayers = location.pathname.includes("/home")
    ? gameData.home_skaters
    : gameData.away_skaters;

  const minusPlayers = location.pathname.includes("/away")
    ? gameData.home_skaters
    : gameData.away_skaters;

  useEffect(() => {
    goal && event && ass1 && ass2 && setHeaderFlow(3);
  }, [location]);

  useEffect(() => {
    getGameInfo(params.id);
  }, []);

  useEffect(() => {
    if (gameData && gameData.events) {
      const res = getPlusMinusPlayers(gameData.events);
      if (res.length) {
        setToBeAdded((prevState: any) => ({
          ...prevState,
          plus: res[0].plus,
          minus: res[0].minus,
        }));
      }

      handlePlayersLimits(gameData.events);
    }
  }, [gameData]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={isSkeleton}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ filter: isSkeleton ? "blur(4px)" : "blur(0px)" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              marginTop: "1rem",
              marginBottom: "2rem",
              fontWeight: 600,
            }}
          >
            Goal Entry
          </Typography>
          <Card
            sx={{
              padding: 2,
              boxShadow: "0 2px 4px #483d8b",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={headerFlow} alternativeLabel>
                {["Goal", "Assist1", "Assist2", "Plus / Minus"].map((flow) => (
                  <Step key={flow}>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: "#0A2068",
                        },
                      }}
                      sx={{
                        ".MuiStepLabel-alternativeLabel": {
                          fontSize: "1rem !important",
                        },
                      }}
                    >
                      {flow}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Typography
              variant="h5"
              sx={{ fontWeight: 600, textAlign: "center", marginTop: 4 }}
            >
              {headerFlow === 0
                ? "Goal"
                : headerFlow === 1
                ? "Assist1"
                : headerFlow === 2
                ? "Assist2"
                : "Plus / Minus"}
            </Typography>
            {headerFlow <= 2 ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: { xms: "space-between", xs: "center" },
                  py: 3,
                }}
              >
                {plusPlayers?.map((teamPlayer: any, index: number) => {
                  const isSelected = toBeAdded[
                    headerFlow === 0
                      ? "goal"
                      : headerFlow === 1
                      ? "ass1"
                      : "ass2"
                  ]?.includes(teamPlayer.player_id);
                  const backgroundColor = isSelected
                    ? "#D4B5F7"
                    : "transparent";
                  return (
                    <PlayerCard
                      key={index}
                      jersey={teamPlayer.jersey}
                      name={teamPlayer.name}
                      cardWidth={"11rem"}
                      isClosable={false}
                      style={{ backgroundColor }}
                      onClick={() => handleAddedPlayers(teamPlayer.player_id)}
                    />
                  );
                })}
              </Box>
            ) : (
              <Grid container spacing={7}>
                <Grid
                  item
                  md={6}
                  lg={6}
                  xs={12}
                  sm={12}
                  sx={{
                    opacity: Number(ass2) !== 0 ? 1 : 0.7,
                    pointerEvents: Number(ass2) !== 0 ? "all" : "none",
                  }}
                >
                  <Typography variant="h5" sx={{ marginTop: 3 }}>
                    Plus
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: { xms: "space-between", xs: "center" },
                      paddingBottom: 3,
                    }}
                  >
                    {plusPlayers?.map((teamPlayer: any, index: number) => {
                      const isSelected = toBeAdded["plus"]?.includes(
                        teamPlayer.player_id
                      );
                      const backgroundColor = isSelected
                        ? "#D4B5F7"
                        : "transparent";
                      return (
                        <PlayerCard
                          key={index}
                          jersey={teamPlayer.jersey}
                          name={teamPlayer.name}
                          cardWidth={"11rem"}
                          isClosable={false}
                          style={{ backgroundColor }}
                          onClick={() =>
                            handleAddedPlayers(teamPlayer.player_id, "plus")
                          }
                        />
                      );
                    })}
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "red",
                      display: arePlayersValid("plus") ? "none" : "block",
                    }}
                  >
                    {`* ${maxLimits.plus} Plus player selections are needed.`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  lg={6}
                  xs={12}
                  sm={12}
                  sx={{
                    opacity: Number(ass2) !== 0 ? 1 : 0.7,
                    pointerEvents: Number(ass2) !== 0 ? "all" : "none",
                  }}
                >
                  <Typography variant="h5" sx={{ marginTop: 3 }}>
                    Minus
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: { xms: "space-between", xs: "center" },
                      paddingBottom: 3,
                    }}
                  >
                    {minusPlayers?.map((teamPlayer: any, index: number) => {
                      const isSelected = toBeAdded["minus"]?.includes(
                        teamPlayer.player_id
                      );
                      const backgroundColor = isSelected
                        ? "#D4B5F7"
                        : "transparent";
                      return (
                        <PlayerCard
                          key={index}
                          jersey={teamPlayer.jersey}
                          name={teamPlayer.name}
                          cardWidth={"11rem"}
                          isClosable={false}
                          style={{ backgroundColor }}
                          onClick={() =>
                            handleAddedPlayers(teamPlayer.player_id, "minus")
                          }
                        />
                      );
                    })}
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "red",
                      display: arePlayersValid("minus") ? "none" : "block",
                    }}
                  >
                    {`* ${maxLimits.minus} Minus player selections are needed.`}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Card>
        </Box>
        <FooterButtons
          isSaveDisable={Number(ass2) === 0 ? true : !arePlayersValid()}
          isNextDisable={Number(ass2) === 0 ? true : !arePlayersValid()}
          onBackClick={() => {
            if (headerFlow === 0 || (goal && event && ass1 && ass2)) {
              window.history.back();
            } else {
              setToBeAdded({
                goal: [],
                ass1: [],
                ass2: [],
                plus: [],
                minus: [],
              });
              setHeaderFlow((prev) =>
                goal && event && ass1 && ass2
                  ? prev === 3
                    ? 2
                    : prev
                  : prev !== 0
                  ? prev - 1
                  : prev
              );
            }
          }}
          onNextClick={() => {
            if (goal && event && ass1 && ass2) {
              headerFlow === 2 ? setHeaderFlow(3) : window.history.back();
            } else {
              headerFlow === 3
                ? window.history.back()
                : setHeaderFlow((prev) => prev + 1);
            }
          }}
          onSaveClick={handleModGoal}
          onClearClick={() =>
            setToBeAdded({ goal: [], ass1: [], ass2: [], plus: [], minus: [] })
          }
        />
      </Box>
    </>
  );
};

export default GoalEntry;
